import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { StudioGraphQLClient } from "@screencloud/studio-graphql-client";
import Loading from "../../components/Loading";
import Card from "../../components/Card";
import AddMenu from "../../components/AddMenu";

export interface ScreensWallProps {
  pageId: string;
  name: string;
  template: string;
  endpoint: string;
  menus?: any[];
  api_key: string;
  space_id: string;
  studio_url: string;
  player_url: string;
  camera_url: string;
}

const ScreensWall = (props: ScreensWallProps) => {
  const navigate = useNavigate();
  const {
    pageId,
    name,
    endpoint,
    api_key,
    space_id,
    studio_url,
    player_url,
    camera_url,
    template,
  } = props;
  const [screens, setScreens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [layout, setLayout] = useState([] as any);
  const maxCols = template.split("\n")[0].split(",").length;

  function generateLayout(template: string) {
    const gridRows = template.split("\n");

    let newLayout: any = [];

    for (let i = 0; i < maxCols; i++) {
      newLayout.push([]);
    }

    gridRows.reduce((acc, row, rowIdx) => {
      const col = row.split(",");

      col.reduce((acc, item, colIdx) => {
        const numberOfSubGrid = Number(
          item.replaceAll("[", "").replaceAll("]", "")
        );

        const subGridTemplate = Array(numberOfSubGrid).fill({
          name: `[S${colIdx + 1}-${rowIdx + 1}]`,
        });

        const subGrids = subGridTemplate.map((subGrid, subGridIdx) => {
          return {
            ...subGrid,
            name: `[S${colIdx + 1}-${rowIdx + 1}-${subGridIdx + 1}]`,
          };
        });

        newLayout[colIdx].push({
          name: `S${colIdx + 1}-${rowIdx + 1}`,
          grids: numberOfSubGrid > 1 ? subGrids : [],
        });
        return acc;
      }, []);

      return acc;
    }, []);

    setLayout(newLayout);
    return newLayout;
  }

  useEffect(() => {
    generateLayout(template);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, template]);

  const client = new StudioGraphQLClient({
    endpoint: endpoint,
    token: api_key,
  });

  const queryScreensBySpaceId = `
    query allScreens {
      allScreens(
        orderBy:NAME_ASC,
        filter:{
          spaceId:{
            equalTo: "${space_id}"
          }
      }) {
        nodes {
          id
          name
          status
          device
          content
          deviceModel
          timezone
          operatingHours
          playerRelease
        }
        totalCount
      }
    }  
  `;

  useEffect(() => {
    getScreens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, template]);

  const getScreens = useCallback(() => {
    setLoading(true);
    client.request(queryScreensBySpaceId).then((result) => {
      if (result.errors) {
        console.log("The request was unsuccessful", result.errors);
      } else {
        setScreens(result.allScreens.nodes);
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  function closeModal() {
    navigate(``);
    setIsOpen(false);
  }

  function openSettings() {
    navigate(`?id=${pageId}`);
    setIsOpen(true);
  }

  const renderDialog = () => {
    return (
      <Transition appear show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full w-2/4 h-1/4 p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Page Settings
                </Dialog.Title>
                <div>
                  <AddMenu
                    name={name}
                    api_key={api_key}
                    endpoint={endpoint}
                    space_id={space_id}
                    studio_url={studio_url}
                    player_url={player_url}
                    camera_url={camera_url}
                    template={template}
                    isUpdate={true}
                    onClose={closeModal}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {renderDialog()}
      <div className="flex space-x-2 p-4 w-full justify-between">
        <div>
          {camera_url !== "" && (
            <a
              href={camera_url}
              target="_blank"
              rel="noreferrer"
              type="button"
              className="inline-block px-6 py-2 m-1 border-2 border-red-700 text-red-700 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            >
              Live Cam
            </a>
          )}
          {studio_url !== "" && (
            <a
              href={studio_url}
              target="_blank"
              rel="noreferrer"
              type="button"
              className="inline-block px-6 py-2 m-1 border-2 border-amber-500 text-amber-500 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            >
              View in Studio
            </a>
          )}
        </div>
        <div>
          <button
            onClick={openSettings}
            type="button"
            className="inline-block px-6 py-2 m-1 border-2 border-gray-800 text-gray-800 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            Page Settings
          </button>
        </div>
      </div>
      <div
        className={`card-wrapper grid ml-4 mr-4 gap-4 relative`}
        style={{ gridTemplateColumns: `repeat(${maxCols}, 1fr)` }}
      >
        {layout.map((col: any, colIndex: number) => {
          return (
            <div key={colIndex}>
              {col.map((item: any, idx: number) => {
                const screen = screens.find((screen: any) =>
                  screen.name.includes(item?.name)
                );
                let subScreens: any[] = [];
                item.grids.map((grid: any, idx: number) => {
                  const subScreen = screens.find((x: any) =>
                    x.name.includes(grid.name)
                  );
                  subScreens.push(subScreen);
                  return subScreen;
                });

                const gridSize =
                  item.grids.length > 0 ? Math.sqrt(item.grids.length) : 1;

                return (
                  <div
                    key={idx}
                    className={`card-wrapper mb-4 rounded-2xl relative 
                      ${
                        item.grids.length > 0
                          ? "card-group pb-2 pr-2 border rounded-2xl grid grid-cols-1 "
                          : "card p-4 "
                      } 
                      ${!screen && "inactive"}`}
                    style={{ gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}
                  >
                    <Card
                      key={idx}
                      pageId={pageId}
                      name={item.name}
                      screen={screen}
                      subScreens={subScreens}
                      subGrids={item?.grids}
                      endpoint={endpoint}
                      apiKey={api_key}
                      playerUrl={player_url}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      )
    </>
  );
};

export default ScreensWall;
