export interface AppConfig {
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  baseUrl: string;
  environment: "development" | string;
  graphqlEndpoint: string;
  reactEnvironment: "development" | "production" | string;
  release: string;
  studio: {
    baseUrl: string;
  };
  version: string;
}

const release = `${process.env.REACT_APP_SENTRY_RELEASE}` || "development";
const environment = `${process.env.REACT_APP_SENTRY_ENV}` || "development";

export const appConfig: Readonly<AppConfig> = Object.freeze({
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
    appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
  },
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  environment,
  graphqlEndpoint: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
  release,
  reactEnvironment: `${process.env.REACT_APP_ENV}` || "development",
  studio: {
    baseUrl: `${process.env.REACT_APP_STUDIO_BASE_URL}` || "",
  },
  version: `${process.env.REACT_APP_SENTRY_RELEASE}` || "development",
});
