import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import './style.css';
export interface DashboardProps {
  menus: any[]
}


const Dashboard = (props: DashboardProps) => {
  const { menus } = props;
  const navigate = useNavigate();

  if (menus.length === 0) {
    return (
      <Loading />
    );
  }

  return (
    <div className="max-w-5xl pt-10 md:pt-32 pb-24 mx-auto">
      <h1 className="text-xl uppercase font-bold ml-4 mb-6 opacity-70">Shelves</h1>
      <div className={`grid grid-cols-3`}>
        {menus.map((menu: any) => (
          <div className="env-card p-6 m-4 cursor-pointer" key={menu.path} onClick={() => navigate(`/${menu.path}`)}>
            <h1 className="text-3xl mb-3 font-bold">{menu.name}</h1>
            <p className="opacity-50">{menu.studio_url}</p>
            <p className="opacity-50">
              {menu.camera_url && <a href={menu.camera_url} target="_blank" rel="noopener noreferrer" className="inline-block px-6 py-2 mt-3 border-2 border-red-700 text-red-700 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Live Camera</a>}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Dashboard;