import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import firebase from "firebase/compat/app";

interface NoteDetailProps {
  pageId: string;
  gridName: any;
}

const NoteDetail = (props: NoteDetailProps) => {
  const { pageId, gridName } = props;
  const [shelvesData, setShelvesData] = useState([]);
  const pageRef = firebase.firestore().collection("/menus").doc(pageId);
  const noteValue = shelvesData && shelvesData[gridName] && shelvesData[gridName]['note'] ? shelvesData[gridName]['note'] : undefined;

  useEffect(() => {
    const unsubscribe = pageRef.onSnapshot(onDataChange);
    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDataChange = () => {    
    pageRef.get().then((doc) => {
        if (doc.exists) {
          setShelvesData(doc.data()?.shelves);
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
  };
  
  function onNoteChanges(note: string) {
    pageRef.update({
      ["shelves." + gridName + ".note"]: note
    })
  }

  return (
    <div className="p-4">
      <span></span>
      <textarea rows={20} onChange={debounce((e) => onNoteChanges(e.target.value), 400)} className="rounded-md border w-full p-2" defaultValue={noteValue} />
    </div>
  )
}

export default NoteDetail;