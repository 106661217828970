import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { useLocation } from "react-router-dom";

export interface AddMenuProps {
  api_key?: string
  endpoint?: string
  name?: string
  space_id?: string
  studio_url?: string
  player_url?: string
  camera_url?: string
  template?: string
  isOpen?: boolean
  isUpdate?: boolean
  onClose?: () => void
}

const defaultTemplate = "[1],[1],[1],[1]\n[1],[1],[1],[1]\n[1],[1],[1],[1]\n[1],[1],[1],[1]";

const AddMenu = (props: AddMenuProps) => {
  const location = useLocation();
  const { name, api_key, space_id, studio_url, camera_url, player_url, endpoint, template, isUpdate, onClose } = props;

  const [formName, setFormName] = useState(name);
  const [formApiKey, setFormApiKey] = useState(api_key);
  const [formEndpoint, setFormEndpoint] = useState(endpoint);
  const [formTemplate, setFormTemplate] = useState(template ?? defaultTemplate)
  const [formSpaceId, setFormSpaceId] = useState(space_id);
  const [formStudioUrl, setFormStudioUrl] = useState(studio_url);
  const [formPlayerUrl, setFormPlayerUrl] = useState(player_url);
  const [formCameraUrl, setFormCameraUrl] = useState(camera_url);

  const menusRef = firebase.firestore().collection("/menus");

  const onDataChange = () => {
    menusRef.get().then((snapshot: any) => {
      let menuList: any = [];
      snapshot.forEach((childSnapshot: any) => {
        const id = childSnapshot.id;
        const data = childSnapshot.data();
        menuList.push({ id, ...data });
      });
    });
  };

  useEffect(() => {
    const unsubscribe = menusRef.onSnapshot(onDataChange);
    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const onUpdate = () => {
    const id = location.search.split("=")[1];

    menusRef.doc(id).update({
      name: formName,
      path: formName?.replaceAll(" ", "-").toLowerCase(),
      endpoint: formEndpoint,
      api_key: formApiKey,
      space_id: formSpaceId,
      template: formTemplate,
      studio_url: formStudioUrl ?? '',
      player_url: formPlayerUrl ?? '',
      camera_url: formCameraUrl ?? '',
    });
    onClose && onClose();
  }

  const onSave = () => {
    menusRef.add({
      name: formName,
      path: formName?.replaceAll(" ", "-").toLowerCase(),
      endpoint: formEndpoint,
      api_key: formApiKey,
      space_id: formSpaceId,
      template: formTemplate,
      studio_url: formStudioUrl ?? ' ',
      camera_url: formCameraUrl ?? ' ',
    })
    .then(function(docRef: any) {
        onClose && onClose()
    })
    .catch(function(error: Error) {
        console.error("Error adding Tutorial: ", error);
    });;
  }

  // function handleFormSubmit(e: any) {
  //   e.preventDefault();
  //   onSave();
  // }

  return (
    <div className="App flex items-center justify-center">
      <div className="px-12 py-8 w-full text-left bg-white ">
        {/* <form onSubmit={(e) => handleFormSubmit(e)}> */}
        <div>
          <label htmlFor="exampleFormControlInput1" className="form-label inline-block mb-2 text-black-700">
            Name
          </label>
          <input onChange={(e) => setFormName(e.target.value)} className="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" name="name" defaultValue={formName} />
        </div>
        {/* <div><input onChange={(e) => setPath(e.target.value)} className="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" name="path" placeholder="Path"/></div> */}
        <div>
          <label htmlFor="exampleFormControlInput1" className="form-label inline-block mb-2 text-black-700">
            Endpoint
          </label>
          <input required onChange={(e) => setFormEndpoint(e.target.value)} className="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" name="endpoint" defaultValue={formEndpoint} />
        </div>
        <div>
          <label htmlFor="exampleFormControlInput1" className="form-label inline-block mb-2 text-black-700">
            API Key
          </label>
          <input required onChange={(e) => setFormApiKey(e.target.value)} className="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" name="token" defaultValue={formApiKey} />
        </div>
        <div>
          <label htmlFor="exampleFormControlInput1" className="form-label inline-block mb-2 text-black-700">
            Space ID
          </label>
          <input required onChange={(e) => setFormSpaceId(e.target.value)} className="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" name="style" defaultValue={formSpaceId} />
        </div>
        <div>
          <label htmlFor="exampleFormControlInput1" className="form-label inline-block mb-2 text-black-700">
            Player URL
          </label>
          <input onChange={(e) => setFormPlayerUrl(e.target.value)} className="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" name="style" defaultValue={formPlayerUrl} />
        </div>
        <div>
          <label htmlFor="exampleFormControlInput1" className="form-label inline-block mb-2 text-black-700">
            Studio URL
          </label>
          <input onChange={(e) => setFormStudioUrl(e.target.value)} className="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" name="style" defaultValue={formStudioUrl} />
        </div>
        <div>
          <label htmlFor="exampleFormControlInput1" className="form-label inline-block mb-2 text-black-700">
            Camera URL
          </label>
          <input onChange={(e) => setFormCameraUrl(e.target.value)} className="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" name="style" defaultValue={formCameraUrl} />
        </div>
        {/* <div><input className="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" name="style" placeholder="Template"/></div> */}

        <label htmlFor="exampleFormControlInput1" className="form-label inline-block mb-2 text-black-700">
          Layout<br />
          <span className="text-xs text-gray-600">4 = 2 Columns &amp; 2 Rows , 9 = 3 Columns &amp; 3 Rows</span>
        </label>

        <textarea
          rows={8}
          className="border rounded-md w-full font-mono"
          onChange={(e) => setFormTemplate(e.target.value)}
          defaultValue={formTemplate}
        />

        {isUpdate ? (
          <button onClick={onUpdate} className="px-6 py-2 mt-4 text-white bg-slate-600 rounded-lg hover:bg-slate-900">Update</button>
        ) : (
          <button onClick={onSave} className="px-6 py-2 mt-4 text-white bg-slate-600 rounded-lg hover:bg-slate-900">Save</button>
        )}
        
        {/* </form> */}
      </div>
    </div>
  );
}

export default AddMenu;
