
import { FunctionComponent } from "react";

const devicesResolutions: Record<string, { height: number; width: number }> = {
  UHD_4K: {
    height: 2160,
    width: 3880,
  },
  QHD: {
    height: 1440,
    width: 2560,
  },
  FULL_HD_1080P: {
    height: 1080,
    width: 1920,
  },
  HD_720P: {
    height: 720,
    width: 1280,
  },
  SD_480P: {
    height: 480,
    width: 640,
  },
  IPAD: {
    height: 768,
    width: 1024,
  },
  IPHONE_X: {
    height: 375,
    width: 812,
  },
}

enum Device {
  UHD_4K = "UHD_4K",
  QHD = "QHD",
  FULL_HD_1080P = "FULL_HD_1080P",
  HD_720P = "HD_720P",
  SD_480P = "SD_480P",
  IPAD = "IPAD",
  IPHONE_X = "IPHONE_X",
}

export interface IDeviceWrapperProps {
  device?: Device | "UHD_4K" | "QHD" | "FULL_HD_1080P" | "HD_720P" | "SD_480P" | "IPAD" | "IPHONE_X"
  width?: number
  height?: number
  previewWidth?: number
  previewHeight?: number
}

export const DeviceWrapper: FunctionComponent<IDeviceWrapperProps> = (
  {
    device,
    width,
    height,
    previewWidth,
    previewHeight,
    children,
  },
) => {

  const ensureDevice = device || Device.FULL_HD_1080P
  const deviceHeight = height || devicesResolutions[ensureDevice!].height
  const deviceWidth = width || devicesResolutions[ensureDevice!].width
  const pWidth = previewWidth || 1280
  const pHeight = previewHeight || 720
  const scale = Math.min(pHeight / deviceHeight, pWidth / deviceWidth)

  return (
    <div
      style={{
        alignItems: "start",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        position: "relative",
        width: "100%",
      }}
    >
      <div
        style={{
          background: "rgb(0, 0, 0)",
          borderColor: "rgb(0, 0, 0)",
          borderImage: "initial",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "7px",
          boxShadow: "rgba(0, 0, 0, 0.5) 0px 90px 40px -80px",
          height: `${deviceHeight}px`,
          position: "absolute",
          transform: `scale(${scale > 1 ? 1 : scale})`,
          transformOrigin: "center top",
          width: `${deviceWidth}px`,
        }}
      >
        {children}
      </div>
    </div>
  )
}
