import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import { AuthContext } from "../../context/AuthContext";
import { signInWithGoogle } from '../../firebaseSetup';
import logo from "../../images/logo.svg";
import '../../App.css';

function SignIn() {
  const user = useContext(AuthContext);
  const navigation = useNavigate()

  function logOut() {
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
      navigation("/")
    }).catch(function(error) {
      // An error happened.
    });
  }

  if (!user) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <div className="max-w-xs sm:max-w-xl">
            <div className="flex justify-center">
              <img src={logo} alt="Logo" width="180" />
            </div>

            <div className="max-w-sm mt-5 text-lg text-center">
              <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">Test Lab Management Console</h2>
            </div>

            <div className="flex flex-col justify-center mt-8">
              <button onClick={() => signInWithGoogle()} className="px-6 py-3 mt-4 font-semibold text-gray-900 bg-white border-2 border-gray-500 rounded-md shadow outline-none hover:bg-blue-50 hover:border-blue-400 focus:outline-none"><svg xmlns="http://www.w3.org/2000/svg" className="inline w-4 h-4 mr-3 text-gray-900 fill-current" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path></svg>Sign in with Google</button>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    navigation('/dashboard')
  }
  
  return (
    <div className="App bg-gray-100 font-sans leading-normal tracking-normal">
      <nav id="header" className="bg-white fixed w-full z-10 top-0 shadow">
        <div className="w-full container mx-auto flex flex-wrap items-center mt-0 pt-3 pb-3 md:pb-0">
            <div className="w-1/2 pl-2 md:pl-0">
              <h1 className="float-left font-medium text-2xl">Test Lab Management Console</h1>
            </div>
            <div className="w-1/2 pr-0">
                <div className="flex relative inline-block float-right">

                    <div className="relative text-sm">
                        <button id="userButton" className="flex items-center focus:outline-none mr-3">
                            <img className="w-8 h-8 rounded-full mr-4" src={user.photoURL || ''} alt="Avatar of User" /> <span className="hidden md:inline-block">{user.displayName}</span>
                            <svg className="pl-2 h-2" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" enableBackground="new 0 0 129 129">
                                <g>
                                  <path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z" />
                                </g>
                            </svg>
                        </button>
                        <div id="userMenu" className="bg-white rounded shadow-md mt-2 absolute mt-12 top-0 right-0 min-w-full overflow-auto z-30 invisible">
                            <ul className="list-reset">
                                <li><a href="/#" className="px-4 py-2 block text-gray-900 hover:bg-gray-400 no-underline hover:no-underline">My account</a></li>
                                <li><a href="/#" className="px-4 py-2 block text-gray-900 hover:bg-gray-400 no-underline hover:no-underline">Notifications</a></li>
                                <li>
                                    <hr className="border-t mx-2 border-gray-400" />
                                </li>
                                <li><a href="/#" className="px-4 py-2 block text-gray-900 hover:bg-gray-400 no-underline hover:no-underline">Logout</a></li>
                            </ul>
                        </div>
                    </div>


                    <div className="block lg:hidden pr-4">
                        <button id="nav-toggle" className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-900 hover:border-teal-500 appearance-none focus:outline-none">
                            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </button>
                    </div>
                </div>

            </div>


            <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white" id="nav-content">
                <ul className="list-reset lg:flex flex-1 items-center px-4 md:px-0">
                    <li className="mr-6 my-2 md:my-0">
                        <a href="/dashboard" className="block py-1 md:py-3 pl-1 align-middle text-pink-600 no-underline hover:text-gray-900 border-b-2 border-orange-600 hover:border-orange-600">
                            <span className="pb-1 md:pb-0 text-sm">Dashboard</span>
                        </a>
                    </li>
                    <li className="mr-6 my-2 md:my-0">
                        <a href="/staging-eu" className="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-pink-500">
                            <span className="pb-1 md:pb-0 text-sm">Staging EU</span>
                        </a>
                    </li>
                    <li className="mr-6 my-2 md:my-0">
                        <a href="/staging-us" className="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-purple-500">
                            <span className="pb-1 md:pb-0 text-sm">Staging US</span>
                        </a>
                    </li>
                    <li className="mr-6 my-2 md:my-0">
                        <a href="/production-eu" className="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-green-500">
                            <span className="pb-1 md:pb-0 text-sm">Production EU</span>
                        </a>
                    </li>
                    <li className="mr-6 my-2 md:my-0">
                        <a href="/production-us" className="block py-1 md:py-3 pl-1 align-middle text-gray-500 no-underline hover:text-gray-900 border-b-2 border-white hover:border-red-500">
                            <span className="pb-1 md:pb-0 text-sm">Production US</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div>
              <button onClick={() => logOut()}>Log Out</button>
            </div>

        </div>
        </nav>
    </div>
  );
}

export default SignIn;
