import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import { AuthContext } from "../context/AuthContext";
import { Menu, Transition } from '@headlessui/react'
import Nav from '../components/Nav';

export interface LayoutProps {
  children?: React.ReactNode;
  menus: any[];
}

const Layout = (props: LayoutProps) => {
  const user = useContext(AuthContext);
  const navigation = useNavigate()
  const { children, menus } = props;

  function logOut() {
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
      navigation("/")
    }).catch(function(error) {
      // An error happened.
    });
  }

  return (
    <div className="flex h-screen overflow-y-hidden">
      <div className="flex flex-col flex-1 h-full overflow-hidden">
        <header className="flex-shrink-0 border-b">
            <div className="flex items-center justify-between p-2">
              
              <div className="flex items-center space-x-3">
                <Link to="/dashboard"><span className="p-2 text-xl font-semibold tracking-wider uppercase inline-block">Testlab Management Console</span></Link>
              </div>

              <Nav menus={menus} />

              <div className="relative flex items-center space-x-3">

                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full px-4 py-2 text-sm font-medium rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        <span><img className="w-8 h-8 rounded-full mr-4" src={user?.photoURL || ''} alt="Avatar of User" /></span>
                        {/* <span className="hidden md:inline-block pt-1 relative">{user?.displayName}</span> */}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute z-50 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? 'bg-slate-500 text-black bg-opacity-10' : 'text-grey-900'
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              Settings
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="px-1 py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => logOut()}
                              className={`${
                                active ? 'bg-slate-500 text-black bg-opacity-10' : 'text-grey-900'
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              Log Out
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

              </div>
            </div>
          </header>

          <main className="flex-1 max-h-full overflow-hidden overflow-y-scroll">
            {children}
          </main>
      </div>
    </div>
  )
}

export default Layout