import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react';
import AddMenu from '../AddMenu';

interface NavProps {
  menus: any[];
}

const Nav = (props: NavProps) => {
  const location = useLocation();
  const { menus } = props;
  const [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openSettings() {
    setIsOpen(true)
  }

  const renderDialog = () => {
    return (
      <Transition appear show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full w-2/4 h-1/4 p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  New Environment
                </Dialog.Title>
                <div>
                  <AddMenu onClose={closeModal} />
                </div>

                {/* <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    Got it, thanks!
                  </button>
                </div> */}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    )
  }


  return (
    <div
      className="w-full z-0 relative flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 ml-6"
      id="nav-content"
    >
      {renderDialog()}
      <ul className="list-reset lg:flex flex-1 items-center px-4 md:px-0">
        {/* <li className="mr-6 my-2 md:my-0">
          <a
            href="/dashboard"
            className={`${
              location.pathname.replaceAll("/", "") === "dashboard"
                ? "border-b-2"
                : null
            } block py-1 md:py-3 pl-1 align-middle text-gray-600 no-underline hover:text-gray-900 border-orange-600 hover:border-orange-600`}
          >
            <span className="pb-1 md:pb-0 text-sm">Dashboard</span>
          </a>
        </li> */}
        {menus.length > 0 && menus
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((menu) => {
            return (
              <li className="mr-6 my-2 md:my-0" key={menu.path}>
                <Link
                  to={`/${menu.path}`}
                  className={`${
                    location.pathname.replaceAll("/", "") === menu.path
                      ? "border-b-2"
                      : null
                  } block py-1 md:py-3 pl-1 align-middle text-slate-600 no-underline hover:text-gray-900 border-slate-600 hover:border-black`}
                >
                  <span className="pb-1 md:pb-0 text-sm">{menu.name}</span>
                </Link>
              </li>
            );
          })}
        <li className="mr-6 my-2 md:my-0">
          <button
            onClick={() => openSettings()}
            className="block py-1 md:py-3 pl-1 align-middle text-gray-600 no-underline hover:text-gray-900 border-orange-600 hover:border-orange-600"
          >
            <span className="pb-1 md:pb-0 text-sm">+</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Nav;
