import React, { useRef, useEffect, useState } from "react";
import DeviceIcon from "@screencloud/screencloud-ui-components/.dist/components/device-icon/index";
import { debounce } from "lodash";
import { Dialog, Transition } from '@headlessui/react';
import { StudioGraphQLClient } from "@screencloud/studio-graphql-client";
import { renderStudioPlayer } from '@screencloud/studio-player-sdk';
import firebase from "firebase/compat/app";
import { DeviceWrapper } from '../DeviceWrapper';
export interface ScreenDetailProps {
  screen: any
  content: any
  pageId?: string
  apiKey: string
  endpoint?: string
  playerUrl?: string
}

const refreshScreensMutation = `
  mutation refreshScreen($input:RefreshScreenInput!) {
    refreshScreen(input:$input) {
      screen {
        id
        name
      }
    }
  }
`

const clearCacheScreensMutation = `
  mutation clearCacheScreen($input:ClearCacheScreenInput!) {
    clearCacheScreen(input:$input) {
      screen {
        id
        name
      }
    }
  }
`

const ScreenDetail = (props:ScreenDetailProps) => {
  const { screen, content, pageId, apiKey, endpoint, playerUrl } = props;
  const [shelvesData, setShelvesData] = useState([]);
  const [openRefreshScreen, setOpenRefreshScreen] = useState(false)
  const [openClearCacheScreen, setOpenClearCacheScreen] = useState(false)

  const gridName = screen?.name.match(/\[(.*?)\]/g) ? screen?.name.match(/\[(.*?)\]/g)[0]?.replace(/\[|\]/g, '') : '';
  const playerPreviewTarget = useRef<HTMLDivElement>()
  const playerRegion = playerUrl?.includes('eu') ? 'eu' : 'us';
  const playerEnv = playerUrl?.includes('staging') ? 'staging' : 'production';
  const ipAddressValue = shelvesData && shelvesData[gridName] && shelvesData[gridName]['ip_address'] ? shelvesData[gridName]['ip_address'] : undefined;
  const noteValue = shelvesData && shelvesData[gridName] && shelvesData[gridName]['note'] ? shelvesData[gridName]['note'] : undefined;
  const now = new Date().getTime();
  const startTime = new Date(now - 24 * 60 * 60 * 1000).getTime();


  const client = new StudioGraphQLClient({ 
    endpoint: endpoint,
    token: apiKey,
  });

  useEffect (() => {
    if (playerPreviewTarget.current) {
      renderStudioPlayer({
        target: playerPreviewTarget.current,
        region: playerRegion, // eu | us
        token: apiKey,
        contentPath: `/screen/${screen?.id}`,
        environment: playerEnv, // production | staging
        playerUrl: playerUrl,
        context: { userInteractionEnabled: true },
        features: { offlineStorage: false },
        device: {
          platform: screen?.device.devicePlatform,
          model: screen?.device.deviceModel,
        },
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pageRef = firebase.firestore().collection("/menus").doc(pageId);

  useEffect(() => {
    const unsubscribe = pageRef.onSnapshot(onDataChange);
    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDataChange = () => {    
    pageRef.get().then((doc) => {
        if (doc.exists) {
          setShelvesData(doc.data()?.shelves);
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
  };

  function onNoteChanges(note: string) {
    pageRef.update({
      ["shelves." + gridName + ".note"]: note
    })
  }

  function onIPChanges(ip: string) {
    pageRef.update({
      ["shelves." + gridName + ".ip_address"]: ip
    })
  }

  function handleRefreshScreen() {
    const refreshScreenInput = {
      input: {
        screenId: screen?.id,
        device: screen?.device,
      }
    }

    client.request(refreshScreensMutation, refreshScreenInput).then((result) => {
      
    });
  }

  function handleClearCacheScreen() {
    const clearCacheScreenInput = {
      input: {
        screenId: screen?.id,
        device: screen?.device,
      }
    }

    client.request(clearCacheScreensMutation, clearCacheScreenInput).then((result) => {

    });
  }

  const offlineStyle = {
    color: screen?.device.connection === "online" ? '#1f2027' : '#9D9D9D',
  }

  const renderRefreshConfirmation = () => {
    return (
      <Transition appear show={openRefreshScreen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setOpenRefreshScreen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-2/3 h-1/4 p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl bg-white"
                style={{ maxWidth: '460px', minHeight: '100px' }}
              >
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6"
                >
                  <></>
                </Dialog.Title>
                <div>
                  <h1 className="text-2xl mb-8">Refresh a selected screen?</h1>
                  <button className="rounded bg-slate-600 hover:bg-slate-900 text-white p-2 pl-4 pr-4 text-lg mr-2" onClick={() => {setOpenRefreshScreen(false); handleRefreshScreen()}}>OK</button> <button className="" onClick={() => setOpenRefreshScreen(false)}>Cancel</button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    )
  }
  
  const renderClearCacheConfirmation = () => {
    return (
      <Transition appear show={openClearCacheScreen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setOpenClearCacheScreen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-2/3 h-1/4 p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl bg-white"
                style={{ maxWidth: '460px', minHeight: '100px' }}
              >
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6"
                >
                  <></>
                </Dialog.Title>
                <div>
                  <h1 className="text-2xl mb-8">Clear all caches on a selected screen?</h1>
                  <button className="rounded bg-slate-600 hover:bg-slate-900 text-white p-2 pl-4 pr-4 text-lg mr-2" onClick={() => {setOpenClearCacheScreen(false); handleClearCacheScreen()}}>OK</button> <button className="" onClick={() => setOpenClearCacheScreen(false)}>Cancel</button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    )
  }

  return (
    <>
    {renderRefreshConfirmation()}
    {renderClearCacheConfirmation()}
    <div className="flex flex-col" style={{minHeight: "700px"}}>

    <div className="flex items-center">
      <span className="mr-2">
      {screen?.device.connection === "online" ? (
        <svg
          className="w-5 h-5 fill-green-600"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="m13 17v9h14v-9zm11 11c0 .6-.4 1-1 1h-6c-.6 0-1-.4-1-1h-3c-1.1 0-2-.9-2-2v-9c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v9c0 1.1-.9 2-2 2z"
            transform="translate(-11 -13)"
          />
        </svg>
      ) : (
        <svg
          className="w-5 h-5 fill-red-400"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="m13 17v9h14v-9zm11 11c0 .6-.4 1-1 1h-6c-.6 0-1-.4-1-1h-3c-1.1 0-2-.9-2-2v-9c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v9c0 1.1-.9 2-2 2z"
            transform="translate(-11 -13)"
          />
        </svg>
      )}
      </span>
      <span className="rounded text-white pl-1 pr-1 mr-1 capitalize text-sm" style={{backgroundColor: screen?.device.connection === "online" ? 'green' : 'red'}}>{screen?.device.connection}</span>
      <span className="text-base uppercase">{screen?.device?.devicePlatform}</span><span className="text-base ml-1 opacity-50 truncate inline-block w-3/6">{screen?.device?.deviceModel}</span>

      <div className="">
        <div className="absolute top-6 right-6 text-xs flex align-center">
          {screen?.device.devicePlatform === "android" && <button onClick={() => setOpenClearCacheScreen(true)} className="font-semibold text-sm text-link ml-2">&#10005; Clear Cache</button>}
          <button onClick={() => setOpenRefreshScreen(true)} className="font-semibold text-sm text-link ml-2">&#8635; Refresh</button>
          <a
            href={`https://app.datadoghq.com/account/login?next=%2Flogs%3Fquery%3D%2540screenId%3A${screen.id}%26cols%3Dhost%252Cservice%252C%2540name%252C%2540contentType%252C%2540screenId%252C%2540orgId%26index%3D%252A%26messageDisplay%3Dinline%26stream_sort%3Ddesc%26from_ts%3D${startTime}%26to_ts%3D${now}%26live%3Dfalse`}
            target="_blank"
            rel="noreferrer"
            className="font-semibold text-sm text-link ml-2"
          >
            &#8599; Datadog
          </a>
        </div>
      </div>
    </div>

    <div className="flex flex-row pb-2 mb-4 mt-4 border-b-1 border-slate-700">
        <div className="device-icon mr-4">
          <DeviceIcon model={screen?.device.deviceModel} platform={screen?.device.devicePlatform} />
        </div>
        <div>
          <span className="text-2xl font-semibold mt-2 mb-2">{screen?.name}</span>
          <p style={offlineStyle}><span className="capitalize bg-slate-100 p-1 text-xs rounded">{screen?.content._ref.type}</span> {content?.name}</p>
        </div>
    </div>
        

        <div className="p-4">
          <div className="screen-preview">
            <div data-testid="player-sdk" className="screen-preview-inner player-preview">
              <DeviceWrapper
                key={screen.id}
                width={screen.device ? screen.device.player_width : undefined}
                height={screen.device ? screen.device.player_height : undefined}
                previewWidth={700}
                previewHeight={400}>
                <div className="sdk-player-container"  ref={playerPreviewTarget as any} />
              </DeviceWrapper>
            </div>
          </div>
        </div>

        <div className="flex justify-between p-2">
          <div className="w-1/2">
            <table className="min-w-full text-sm divide-y-2 divide-gray-200">
              <tbody className="divide-y divide-gray-200">
                <tr>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                    Playing:
                  </td>
                  <td className="px-4 py-2 text-gray-700 whitespace-nowrap"><span className="capitalize bg-slate-100 p-1 text-xs rounded">{screen?.content._ref.type}</span> {content?.name}</td>
                </tr>

                <tr>
                  <td className="px-4 py-2 font-medium whitespace-nowrap">Model:</td>
                  <td className="px-4 py-2 text-gray-700 whitespace-nowrap">{screen?.device.deviceModel}</td>
                </tr>

                <tr>
                  <td className="px-4 py-2 font-medium whitespace-nowrap">Platform:</td>
                  <td className="px-4 py-2 text-gray-700 whitespace-nowrap">{screen?.device.devicePlatform}</td>
                </tr>

                <tr>
                  <td className="px-4 py-2 font-medium whitespace-nowrap">Release Version:</td>
                  <td className="px-4 py-2 text-gray-700 whitespace-nowrap">{screen?.playerRelease}</td>
                </tr>

                <tr>
                  <td className="px-4 py-2 font-medium whitespace-nowrap">Device Timezone:</td>
                  <td className="px-4 py-2 text-gray-700 whitespace-nowrap">{screen?.timezone}</td>
                </tr>

                <tr>
                  <td className="px-4 py-2 font-medium whitespace-nowrap">IP:</td>
                  <td className="px-4 py-2 text-gray-700 whitespace-nowrap">
                    <input className="border border-white p-2 rounded hover:border-slate-400" onChange={debounce((e) => onIPChanges(e.target.value), 200)} defaultValue={ipAddressValue} placeholder="Device IP Address" style={{marginLeft: '-0.6rem'}} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        
        <div className="w-1/2">
          <table className="w-1/2 min-w-full text-sm divide-y-2 divide-gray-200">
            <tbody className="divide-y divide-gray-200">
              <tr>
                <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">Status:</td>
                <td className="px-4 py-2 text-gray-700 whitespace-nowrap">
                  {screen?.device.connection === 'online' ? (
                    <span className="text-green-700 font-medium">Online</span>
                  ) : (
                    <span className="text-red-500">Offline</span>
                  )}
                </td>
              </tr>

              <tr>
                <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">Width:</td>
                <td className="px-4 py-2 text-gray-700 whitespace-nowrap">{screen?.device.player_width}</td>
              </tr>

              <tr>
                <td className="px-4 py-2 font-medium whitespace-nowrap">Height:</td>
                <td className="px-4 py-2 text-gray-700 whitespace-nowrap">{screen?.device.player_height}</td>
              </tr>

              <tr>
                <td className="px-4 py-2 font-medium whitespace-nowrap">Operating Hours:</td>
                <td className="px-4 py-2 text-gray-700 whitespace-nowrap">{screen?.operatingHours?.enable ? 'Yes' : 'No'}</td>
              </tr>

              <tr>
                <td className="px-4 py-2 font-medium whitespace-nowrap">Connected since:</td>
                <td className="px-4 py-2 text-gray-700 whitespace-nowrap truncate w-4/5 inline-block" title={screen?.device.connected_at ? new Date(Number(screen?.device.connected_at * 1000)).toString() : ''}>{screen?.device.connected_at ? new Date(Number(screen?.device.connected_at * 1000)).toString() : ''}</td>
              </tr>

              <tr>
                <td className="px-4 py-2 font-medium whitespace-nowrap">Datadog
                </td>
                <td className="px-4 py-2 text-gray-700 whitespace-nowrap">
                <a
                    href={`https://app.datadoghq.com/account/login?next=%2Flogs%3Fquery%3D%2540screenId%3A${screen.id}%26cols%3Dhost%252Cservice%252C%2540name%252C%2540contentType%252C%2540screenId%252C%2540orgId%26index%3D%252A%26messageDisplay%3Dinline%26stream_sort%3Ddesc%26from_ts%3D${startTime}%26to_ts%3D${now}%26live%3Dfalse`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600"
                  >
                    View in Datadog
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div className="p-4">
        <p>Note:</p>
        <textarea rows={5} onChange={debounce((e) => onNoteChanges(e.target.value), 400)} className="rounded-md border hover:border-slate-400 w-full p-2 text-rose-600" defaultValue={noteValue} />
      </div>


    </div>
  </> 
  )
}

export default ScreenDetail;