import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { Dialog, Transition } from '@headlessui/react';
import DeviceCard from "../DeviceCard";
import NoteDetail from "../NoteDetail";

export interface CardProps {
  subGrids: any;
  pageId: string;
  name: string;
  endpoint: string;
  apiKey: string;
  screen?: any;
  subScreens?: any;
  playerUrl?: string;
}

const Card = (props: CardProps) => {
  const { name, screen, pageId, endpoint, apiKey, subGrids, subScreens, playerUrl } = props;
  const [noteDetailOpen, setNoteDetailOpen] = useState(false)
  const [shelfData, setShelfData] = useState({} as any);

  const hasSubGrids = subGrids && subGrids.length > 0;
  const pageRef = firebase.firestore().collection("/menus").doc(pageId)
  const noteValue = shelfData ? shelfData.note : ''

  useEffect(() => {
    const unsubscribe = pageRef.onSnapshot(onDataChange);
    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const onDataChange = () => {
    pageRef.get().then((doc) => {
      if (doc.exists && doc.data()?.shelves[name]) {
        setShelfData(doc.data()?.shelves[name])
      }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
  }

  function closeModal() {
    setNoteDetailOpen(false)
  }

  function openNoteDetail(deviceName: any) {
    setNoteDetailOpen(true)
  }

  const renderNoteIcon = (gridName: string) => (
    <div className="note-icon absolute right-3 top-3">
      <button onClick={() => openNoteDetail(gridName)}>
        <svg
          className="w-5 h-5 fill-slate-400"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="m8 0c1.44086038 0 2.7741935.36021522 4 1.08064516s2.1989249 1.69354839 2.9193548 2.91935484c.72043 1.22580645 1.0806452 2.55913962 1.0806452 4s-.3602152 2.7741935-1.0806452 4c-.7204299 1.2258065-1.6935483 2.1989249-2.9193548 2.9193548-1.2258065.72043-2.55913962 1.0806452-4 1.0806452s-2.77419355-.3602152-4-1.0806452c-1.22580645-.7204299-2.1989249-1.6935483-2.91935484-2.9193548s-1.08064516-2.55913962-1.08064516-4 .36021522-2.77419355 1.08064516-4 1.69354839-2.1989249 2.91935484-2.91935484 2.55913962-1.08064516 4-1.08064516zm0 3.25c-.36559123 0-.68279586.13440877-.9516129.40322581s-.40322581.58602167-.40322581.9516129.13440877.68279586.40322581.9516129.58602167.40322581.9516129.40322581.68279586-.13440877.9516129-.40322581.40322581-.58602167.40322581-.9516129-.13440877-.68279586-.40322581-.9516129-.58602167-.40322581-.9516129-.40322581zm1.80645161 8.4919355v-.7741936c0-.1075267-.03763457-.1989249-.11290322-.2741935-.07526866-.0752687-.16666683-.1129032-.27419355-.1129032h-.38709678v-3.22580649c0-.10752672-.03763457-.1989249-.11290322-.27419355s-.16666683-.11290322-.27419355-.11290322h-2.06451613c-.10752672 0-.19892489.03763457-.27419355.11290322-.07526865.07526865-.11290322.16666683-.11290322.27419355v.77419355c0 .10752672.03763457.19892489.11290322.27419355.07526866.07526865.16666683.11290322.27419355.11290322h.38709678v2.06451617h-.38709678c-.10752672 0-.19892489.0376345-.27419355.1129032-.07526865.0752686-.11290322.1666668-.11290322.2741935v.7741936c0 .1075267.03763457.1989249.11290322.2741935.07526866.0752687.16666683.1129033.27419355.1129033h2.83870968c.10752672 0 .19892489-.0376346.27419355-.1129033.07526865-.0752686.11290322-.1666668.11290322-.2741935z"
            fillRule="evenodd"
          />
        </svg>
      </button>
    </div>
  )

  const renderNoteDetail = () => {
    return (
      <Transition appear show={noteDetailOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full w-2/5 h-1/4 p-4 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Note:
                </Dialog.Title>
                <div>
                  <NoteDetail pageId={pageId} gridName={name} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    )
  }

  return (
    <>
      {hasSubGrids ? (
        <>
          {subGrids.map((subGrid: any, idx: number) => (
            <div className={`card m-2 mb-0 mr-0 rounded-2xl p-2 relative ${!subScreens[idx]?.name.includes(subGrid?.name) ? ' inactive' : ''}`} key={idx}>
              {renderNoteIcon(subGrid?.name.match(/\[(.*?)\]/g)[0]?.replace(/\[|\]/g, ''))}
              {subScreens[idx]?.name.includes(subGrid?.name) ? (
                <>
                <DeviceCard screen={subScreens[idx]} pageId={pageId} apiKey={apiKey} endpoint={endpoint} playerUrl={playerUrl} />
                </>
              ) : (
                <div className="card-note cursor-pointer absolute" onClick={() => openNoteDetail(name)}>
                <span className="text-xs text-gray-500" >{subGrid?.name} Empty</span>
                {noteValue && <p className="text-xs opacity-70 cursor-pointer" onClick={() => openNoteDetail(name)}>Note: {noteValue}</p>}
                </div>
              )}
            </div>
          ))}
        </>
      ) : (
        <>
        {screen ? (
          <>
          {/* {renderNoteIcon(screen?.name.match(/\[(.*?)\]/g)[0]?.replace(/\[|\]/g, ''))} */}
          <DeviceCard screen={screen} pageId={pageId} apiKey={apiKey} endpoint={endpoint} playerUrl={playerUrl} />
          </>
        ) : (
          <div className="card-note cursor-pointer absolute top-2" onClick={() => openNoteDetail(name)}>
          {renderNoteIcon(name)}
          <span className="text-xs text-gray-500">[{name}] Empty</span>
          {noteValue && <p className="text-sm opacity-70 cursor-pointer" onClick={() => openNoteDetail(name)}>Note: {noteValue}</p>}
          </div>
        )}
        </>
      )}
      {renderNoteDetail()}
    </>
  );
};

export default Card;
