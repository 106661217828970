import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { appConfig } from "./appConfig";

const firebaseConfig = {
  apiKey: appConfig.firebase.apiKey,
  authDomain: appConfig.firebase.authDomain,
  projectId: appConfig.firebase.projectId,
  storageBucket: appConfig.firebase.storageBucket,
  messagingSenderId: appConfig.firebase.messagingSenderId,
  appId: appConfig.firebase.appId,
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().enablePersistence()
.catch((err) => {
    if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
});



export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({
  hd: "screencloud.io",
});

export const signInWithGoogle = () => auth.signInWithPopup(provider);
export default firebase;
